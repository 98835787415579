const COUNTRY_OTHERS_OPTION_VALUE = '996'
const EMEA_HEADQUARTER = '1'
const AME_HEADQUARTER = '2'

;(() => {
  const $registerForm = document.querySelector('.js-agents-register-form')

  if (!$registerForm) return

  // flag to apply changes only after others selected and not in every selection
  let othersWasLastSelection = false

  const caseId = document.querySelector('#case_id')
  if (caseId && caseId.value !== '') {
    EMEAHeadquarterSelected()
  }

  function EMEAHeadquarterSelected() {
    document.querySelectorAll('.js-show-only-emea').forEach(element => {
      element.classList.remove('hidden')
      element.querySelectorAll('input, select').forEach(input => {
        input.dataset.parsleyRequired = true
      })
    })

    document.querySelectorAll('.js-required-only-ame').forEach(element => {
      element.querySelectorAll('input, select').forEach(input => {
        input.dataset.parsleyRequired = false
      })
      element.querySelectorAll('label').forEach(label => {
        label.classList.remove('required-mark')
      })
    })

    document.querySelectorAll('.js-show-only-ame').forEach(element => {
      element.classList.add('hidden')
      element.querySelectorAll('input, select').forEach(input => {
        input.dataset.parsleyRequired = false
      })
    })
  }

  function AMEHeadquarterSelected() {
    document.querySelectorAll('.js-show-only-emea').forEach(element => {
      element.classList.add('hidden')
      element.querySelectorAll('input, select').forEach(input => {
        input.dataset.parsleyRequired = false
      })
    })

    document.querySelectorAll('.js-required-only-ame').forEach(element => {
      element.querySelectorAll('input, select').forEach(input => {
        input.dataset.parsleyRequired = true
      })
      element.querySelectorAll('label').forEach(label => {
        label.classList.add('required-mark')
      })
    })

    document.querySelectorAll('.js-show-only-ame').forEach(element => {
      element.classList.remove('hidden')
      element.querySelectorAll('input, select').forEach(input => {
        input.dataset.parsleyRequired = true
      })
    })
  }

  // Updates validations based on selected country
  if ($registerForm.querySelector('.js-country-selector')) {
    $registerForm.querySelector('.js-country-selector').addEventListener('change', function (event) {
      const countrySelector = event.target
      const $locationInputs = $registerForm.querySelectorAll(
        '.js-community-selector, .js-province-selector, .js-city-selector'
      )
      const selectedHeadquarter = countrySelector.options[countrySelector.selectedIndex].dataset.headquarter

      if (selectedHeadquarter === EMEA_HEADQUARTER) {
        EMEAHeadquarterSelected()
      } else if (selectedHeadquarter === AME_HEADQUARTER) {
        AMEHeadquarterSelected()
      }

      if (countrySelector.value === COUNTRY_OTHERS_OPTION_VALUE) {
        othersWasLastSelection = true
        $locationInputs.forEach($locationInput => {
          // Community, province and city selects are not required anymore
          delete $locationInput.dataset.parsleyRequired
          // are not selectable, so they are hidden
          if ($locationInput.closest('.fz-row')) {
            $locationInput.closest('.fz-row').classList.add('is-hidden')
          }
        })
      } else if (othersWasLastSelection) {
        othersWasLastSelection = false
        $locationInputs.forEach($locationInput => {
          // Community, province and city selects are required
          $locationInput.dataset.parsleyRequired = true
          // are selectable, so restore visibility
          if ($locationInput.closest('.fz-row')) {
            $locationInput.closest('.fz-row').classList.remove('is-hidden')
          }
        })
      }

      $($registerForm).parsley().refresh()
    })
  }

  IB.forms.register_form.init()
})()
